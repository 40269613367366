import {
  ErrorAction,
  errorManager,
  ErrorSeverity,
  PageLoadingContext,
  useHandleQueryWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { useContext, useEffect } from 'react';
import { DeviceType, DXL, IEcommerceCatalogResponse } from '../../api';
import { getEcommerceCatalogDevices } from '../../api/getEcommerceCatalog/getEcommerceCatalogDevices';

interface IElementsCatalog {
  limit?: string;
  category?: DeviceType;
}

const useGetEcommerceCatalogDevices = ({ limit, category }: IElementsCatalog) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const getAllDevices = {
    queryKey: 'ecommerceCatalogDevices',
    queryFn: () =>
      getEcommerceCatalogDevices({
        limit,
        offset: limit && '0',
        resourceCategoryDescription: category && [category],
      }),
    options: {
      onSuccess: (data) => sortByPosition(data),
      onError: (error) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  } as UseQueryConfig;

  const { data } = useHandleQueryWithAuthSSO(getAllDevices);


  useEffect(() => {
    if (data) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [data]);

  return { data };
};

const sortByPosition = (data?: IEcommerceCatalogResponse) =>
  data?.deviceInfo?.sort((a, b) => {
    const posA = a?.characteristic?.find((c) => c.name === 'eCommercePosition')
      ?.value?.[0] as DXL.ICharacteristicValue;
    const posB = b?.characteristic?.find((c) => c.name === 'eCommercePosition')
      ?.value?.[0] as DXL.ICharacteristicValue;
    if (posA?.value && posB?.value) return Number(posA?.value) > Number(posB?.value) ? 1 : -1;
    return posA?.value ? -1 : 1;
  });

export { useGetEcommerceCatalogDevices };

