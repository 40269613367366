import { TrackingPageContext, getFormattedPriceString } from '@vfit/business-data-access';
import { iFrameManager } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { IActionType } from '@vfit/shared/models';
import { useContext } from 'react';
import { IEcommerceCatalogResponse } from '../api';

export const CTC_POSITION = {
  // following the VOLA desc rules NOT USE UNDERSCORE in the value!
  STICKY_OFFER: 'STICKY-OFFER',
  TOP_PRODUCT: 'TOP',
  PRODUCT_DETAIL: 'PRODUCT-DETAIL',
  WHATSAPP_MODAL: 'POPUP-WAPP-CTC',
  PRODUCT_CARD: 'PRODUCT-CARD',
  STICKY_BAR: 'STICKY-BAR',
  COVERAGE_TOOL_KO: 'TOOL-KO',
};

export const useVolaUtils = () => {
  const queryClient = useQueryClient();
  const { descriptionVolaUrl, pageType } = useContext(TrackingPageContext);

  const getPageType = () => {
    // requested for VOLA desc creation
    if (pageType === 'INNOVATIVE') return 'DS';
    return pageType;
  };

  const formatUrlVola = (url: string, product: any) => {
    let urlWithPrice = '';

    if (url.search('DESC=') !== -1) {
      const urlArr = url.split('DESC=');
      const indexNextParam = urlArr[1].indexOf('&');
      const dxlProduct: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
        product?.offerId,
        'ecommerceCatalogCoreOffer',
      ]);

      if (indexNextParam !== -1)
        urlWithPrice = `${urlArr[0]}DESC=${urlArr[1].slice(0, indexNextParam)}${
          product?.taggingProductPrice || getFormattedPriceString(dxlProduct?.productInfo?.[0])
        }${urlArr[1].slice(indexNextParam)}`;
      else
        urlWithPrice =
          url +
          (product?.taggingProductPrice || getFormattedPriceString(dxlProduct?.productInfo?.[0]));
    } else urlWithPrice = url;

    if (sessionStorage.getItem('UTV')) {
      urlWithPrice = urlWithPrice + `&p=${sessionStorage.getItem('UTV')}`;
    }

    return urlWithPrice;
  };

  const formatDynamicVolaUrl = (url: string, desc: string) => {

    let completeUrl = url;

    if (url.search('DESC=') !== -1) {
      const urlArr = url.split('DESC=');
      const indexNextParam = urlArr[1].indexOf('&');

      if (indexNextParam !== -1)
        completeUrl = `${urlArr[0]}DESC=${desc}${urlArr[1].slice(indexNextParam)}`;
      else completeUrl = `${urlArr[0]}DESC=${desc}`;
    }


    if (sessionStorage.getItem('UTV')) {
      completeUrl = completeUrl + `&p=${sessionStorage.getItem('UTV')}`;
    }

    return completeUrl;
  };

  const createUrlDesc = ({
    position,
    productName,
    productPrice,
  }: {
    position?: string;
    productName?: string;
    productPrice?: string;
  }) => {
    if (descriptionVolaUrl) {
      const descriptionUrl = {
        env: `${descriptionVolaUrl?.environment || ''}_`,
        digitalAsset: `${descriptionVolaUrl?.digitalAsset || ''}_`,
        abTest: `${descriptionVolaUrl?.abTestIdentifier || ''}_`,
        channel: `${descriptionVolaUrl?.channeltype || ''}_`,
        pageType: `${descriptionVolaUrl?.pageType || ''}_`,
        page: `${descriptionVolaUrl?.page || ''}_`,
        position: `${position || ''}_`,
        category: `${getPageType() || ''}_`,
        name: `${productName || ''}_`,
        price: productPrice || '_',
      };
      return Object.values(descriptionUrl).join('').toLocaleLowerCase().split(' ').join('-');
    }
    return undefined;
  };

  const openPopupVola = (url: string | undefined, id: string, onClose?: () => void) => {
    if (!url) return;
    iFrameManager.handleIFrame({
      url,
      id,
      onClose,
    });
  };

  const getVolaUrl = (product: any) =>
    product?.actions?.find((b) => b?.type == IActionType.WHATSAPP_URL)?.url;

  return {
    formatUrlVola,
    openPopupVola,
    getVolaUrl,
    createUrlDesc,
    formatDynamicVolaUrl,
  };
};

